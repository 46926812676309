import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SEO from '../components/SEO';
import FVSection from '../components/FVSection';

const Contact: React.FC<{ onNavigate: (path: string) => void }> = ({ onNavigate }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    inquiryType: '',
    companyName: '',
    name: '',
    email: '',
    phoneNumber: '',
    nearestStation: '',
    message: '',
    privacyPolicyAccepted: false,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (location.state) {
      setFormData(location.state);
    }
  }, [location.state]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      const { checked } = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // フィールドの変更時にエラーメッセージをクリア
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors: { [key: string]: string } = {};

    if (!formData.inquiryType) {
      formIsValid = false;
      newErrors.inquiryType = 'お問い合わせ種別を選択してください。';
    }

    if (!formData.companyName) {
      formIsValid = false;
      newErrors.companyName = '会社・団体名を入力してください。';
    }

    if (!formData.name) {
      formIsValid = false;
      newErrors.name = 'お名前を入力してください。';
    }

    if (!formData.email) {
      formIsValid = false;
      newErrors.email = 'メールアドレスを入力してください。';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      newErrors.email = '有効なメールアドレスを入力してください。';
    }

    if (!formData.phoneNumber) {
      formIsValid = false;
      newErrors.phoneNumber = '電話番号を入力してください。';
    } else if (!/^\d{10,11}$/.test(formData.phoneNumber)) {
      formIsValid = false;
      newErrors.phoneNumber = '有効な電話番号を入力してください（ハイフンなし10桁または11桁）。';
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    if (!formData.privacyPolicyAccepted) {
      alert('プライバシーポリシーに同意してください。');
      return;
    }

    navigate('/daichicloud/contact/confirm', { state: formData });
  };

  return (
    <>
      <div className="w-full mt-10">
        <SEO 
            title="お問い合わせ | DAICHI CLOUD"
            description="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            canonicalUrl="https://yourwebsite.com/home"
            ogTitle="お問い合わせ | DAICHI CLOUD"
            ogDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            ogImage="https://yourwebsite.com/images/og-image.jpg"
            ogUrl="https://yourwebsite.com/home"
            twitterTitle="お問い合わせ | DAICHI CLOUD"
            twitterDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            twitterImage="https://yourwebsite.com/images/twitter-image.jpg"
            ldJson={{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "お問い合わせ | DAICHI CLOUD",
                "description": "DAICHI CLOUDについて、お気軽にお問い合わせください",
                "url": "https://yourwebsite.com/progress"
            }}
        />
        <div className='relative'>
          <FVSection
            japaneseTitle="お問い合わせ"
            englishTitle="CONTACT"
            overlayColor="bg-yellow-500"
            src="/images/contact/fv_sp.jpg"
            avifSrc="/images/contact/fv_sp.avif"
            desktopSrc="/images/contact/fv.jpg"
            desktopAvifSrc="/images/contact/fv.avif"
            backgroundAlt="お問い合わせ"
            breadcrumbs={[
              { label: 'トップ', href: '/daichicloud/' },
              { label: 'お問い合わせ' }
            ]}
            onNavigate={onNavigate}
          />
        </div>
      </div>
      <div className='bg-gray-50 w-full'>
        <section className="px-4 pt-8 pb-14 md:pt-20 md:pb-20 max-w-5xl mx-auto bg-gray-50 relative">
          <p>お気軽にお問い合わせください。</p>
          <div className="text-center mt-12">
            <a
              href="tel:042-328-2202"
              className="relative shadow-lg w-full max-w-[400px] rounded-sm flex flex-col items-center mx-auto px-4 py-3 mt-2 bg-yellow-500 text-black border-none group"
            >
              <span className="font-bold text-xl mx-auto">TEL: 042-328-2202 (代)</span>
              <p className="mt-1 text-sm">[受付時間] 平日 9時~12時 / 13時~18時</p>
            </a>
          </div>
          <div>
          </div>
        </section>
        <section className="p-4 max-w-2xl mx-auto">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-md font-bold text-gray-700 flex justify-between items-center">
                お問い合わせ種別
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-sm">必須</span>
              </label>
              <div className="mt-2 space-y-2 text-sm">
                <div>
                  <input
                    type="radio"
                    id="inquiryType-service"
                    name="inquiryType"
                    value="サービス内容・料金について"
                    onChange={handleChange}
                    className="mr-2"
                    checked={formData.inquiryType === "サービス内容・料金について"}
                  />
                  <label htmlFor="inquiryType-service" className="cursor-pointer">
                    サービス内容・料金について
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="inquiryType-partnership"
                    name="inquiryType"
                    value="パートナーシップ・ビジネス提携について"
                    onChange={handleChange}
                    className="mr-2"
                    checked={formData.inquiryType === "パートナーシップ・ビジネス提携について"}
                  />
                  <label htmlFor="inquiryType-partnership" className="cursor-pointer">
                    パートナーシップ・ビジネス提携について
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="inquiryType-other"
                    name="inquiryType"
                    value="その他"
                    onChange={handleChange}
                    className="mr-2"
                    checked={formData.inquiryType === "その他"}
                  />
                  <label htmlFor="inquiryType-other" className="cursor-pointer">
                    その他
                  </label>
                </div>
              </div>
              {errors.inquiryType && <p className="text-red-600 text-sm mt-1">{errors.inquiryType}</p>}
            </div>

            {/* 会社・団体名 */}
            <div>
              <label htmlFor="companyName" className="block text-md font-bold text-gray-700 flex justify-between items-center">
                会社・団体名
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-sm">必須</span>
              </label>
              <input
                type="text"
                name="companyName"
                id="companyName"
                value={formData.companyName}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
              {errors.companyName && <p className="text-red-600 text-sm mt-1">{errors.companyName}</p>}
            </div>

            {/* お名前 */}
            <div>
              <label htmlFor="name" className="block text-md font-bold text-gray-700 flex justify-between items-center">
                お名前
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-sm">必須</span>
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
              {errors.name && <p className="text-red-600 text-sm mt-1">{errors.name}</p>}
            </div>

            {/* メールアドレス */}
            <div>
              <label htmlFor="email" className="block text-md font-bold text-gray-700 flex justify-between items-center">
                メールアドレス
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-sm">必須</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
              {errors.email && <p className="text-red-600 text-sm mt-1">{errors.email}</p>}
            </div>

            {/* 電話番号 */}
            <div>
              <label htmlFor="phoneNumber" className="block text-md font-bold text-gray-700 flex justify-between items-center">
                電話番号
                <span className="bg-red-500 text-white text-xs px-2 py-1 rounded-sm">必須</span>
              </label>
              <input
                type="tel"
                name="phoneNumber"
                id="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
              {errors.phoneNumber && <p className="text-red-600 text-sm mt-1">{errors.phoneNumber}</p>}
            </div>

            {/* ご利用またはご希望の駐車場・駐輪場の最寄り駅 */}
            <div>
              <label htmlFor="nearestStation" className="block text-md font-bold text-gray-700 flex justify-between items-center">
                ご利用またはご希望の駐車場・<br className="block md:hidden" />駐輪場の最寄り駅
                <span className="bg-gray-200 text白 text-xs px-2 py-1 rounded-sm">任意</span>
              </label>
              <input
                type="text"
                name="nearestStation"
                id="nearestStation"
                value={formData.nearestStation}
                onChange={handleChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
            </div>

            {/* お問い合わせ内容 */}
            <div>
              <label htmlFor="message" className="block text-md font-bold text-gray-700 flex justify-between items-center">
                お問い合わせ内容
                <span className="bg-gray-200 text白 text-xs px-2 py-1 rounded-sm">任意</span>
              </label>
              <textarea
                name="message"
                id="message"
                value={formData.message}
                onChange={handleChange}
                rows={10}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm"
              />
            </div>

            {/* プライバシーポリシー */}
            <div className="mt-6">
              <div className="mt-2 p-2 border border-gray-300 rounded-sm h-32 overflow-y-scroll text-xs text-gray-700 bg白">
                {/* プライバシーポリシーの文面 */}
                <p className="text-md text-center mb-2">プライバシーポリシー</p>
                <p>
                  1. 有限会社ダイチ（以下当社）は、個人情報に関する法令およびその他の規範を厳守し、お客さまの大切な個人情報の保護に万全を尽くします。<br /><br />
                  2. 当社は、お客さまの個人情報については、下記の目的の範囲内で適正に取り扱いさせて頂きます。<br />
                  ・当社のサービスに関する情報の伝達及び当社が推奨するスポンサー情報の伝達<br />
                  ・お客様からのお問い合わせ<br />
                  ・ご相談にお答えすること<br /><br />
                  3. 当社は、お客さまの個人情報を適正に取扱うため、社内規程および社内管理体制の整備、従業員の教育、並びに、個人情報への不正アクセスや個人情報の紛失、破壊、改ざんおよび漏洩等防止に関する適切な措置を行い、また、その見直しを継続して図ることにより、個人情報の保護に努めてまいります。<br /><br />
                  4. 当社は、お客さまの個人情報については、上記利用目的を達成するため、業務委託先又は提携先に預託する場合がございます。その場合は、個人情報の保護が十分に図られている企業を選定し、個人情報保護の契約を締結する等必要かつ適切な処置を実施いたします。なお、法令等に基づき裁判所・警察機関などの公的機関から開示の要請があった場合については、当該公的機関に提供することがございます。<br /><br />
                  5. 当社では、お客さまの個人情報の保護を図るために、また、法令その他の規範の変更に対応するために、プライバシーポリシーを改定する事がございます。改定があった場合はＷＥＢページにてお知らせいたします。<br /><br />
                  最終更新日<br />
                  2009年7月15日
                </p>
              </div>
              <p className="text-xs text-gray-700 text-center mt-4">
                上記をご確認いただき「同意する」に<br />チェックを入れてください
              </p>
              <div className="mt-4 flex justify-center">
                <input
                  type="checkbox"
                  name="privacyPolicyAccepted"
                  id="privacyPolicyAccepted"
                  checked={formData.privacyPolicyAccepted}
                  onChange={handleChange}
                  className="mr-2"
                />
                <label htmlFor="privacyPolicyAccepted" className="text-sm text-gray-700">
                  プライバシーポリシーに同意する
                </label>
              </div>
            </div>

            <button
              type="submit"
              disabled={!formData.privacyPolicyAccepted}
              className={`w-full py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-white ${
                formData.privacyPolicyAccepted ? 'bg-gray-800 hover:bg-yellow-600' : 'bg-gray-400 cursor-not-allowed'
              } rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500`}
            >
              入力内容を確認
            </button>
          </form>
        </section>
      </div>
    </>
  );
};

export default Contact;

import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SEO from '../components/SEO';
import FVSection from '../components/FVSection';
import CircularProgress from '@mui/material/CircularProgress'; // Material-UIのCircularProgressをインポート

const ContactConfirm: React.FC<{ onNavigate: (path: string) => void }> = ({ onNavigate }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false); // 送信中かどうかの状態を管理

    const {
        inquiryType,
        companyName,
        name,
        email,
        phoneNumber,
        nearestStation,
        message
    } = location.state as {
        inquiryType: string,
        companyName: string,
        name: string,
        email: string,
        phoneNumber: string,
        nearestStation?: string,
        message?: string
    };

    const handleSubmit = async () => {
        setIsSubmitting(true); // 送信中状態に設定
        try {
            const response = await fetch(`https://6a5dyxqbg1.execute-api.ap-northeast-1.amazonaws.com/dev/api/send-mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    inquiryType,
                    companyName,
                    name,
                    email,
                    phoneNumber,
                    nearestStation,
                    message,
                }),
            });
    
            if (response.ok) {
                navigate('/daichicloud/contact/thank-you');
            } else {
                const errorText = await response.text();
                console.error('Failed to send message:', errorText);
                throw new Error(`Failed to send message: ${errorText}`);
            }
        } catch (error) {
            console.error('Error during form submission:', error);
            alert('メッセージの送信に失敗しました。もう一度お試しください。');
            setIsSubmitting(false); // エラー時は送信中状態を解除
        }
    };

    return (
            <>
            <div className="w-full mt-10 bg-white">
                <SEO 
                    title="お問い合わせ内容確認 | DAICHI CLOUD"
                    description="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
                    canonicalUrl="https://yourwebsite.com/contact/confirm"
                    ogTitle="お問い合わせ内容確認 | DAICHI CLOUD"
                    ogDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
                    ogImage="https://yourwebsite.com/images/og-image.jpg"
                    ogUrl="https://yourwebsite.com/contact/confirm"
                    twitterTitle="お問い合わせ内容確認 | DAICHI CLOUD"
                    twitterDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
                    twitterImage="https://yourwebsite.com/images/twitter-image.jpg"
                    ldJson={{
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "お問い合わせ内容確認 | DAICHI CLOUD",
                        "description": "DAICHI CLOUDに関するお問い合わせ内容をご確認ください",
                        "url": "https://yourwebsite.com/contact/confirm"
                    }}
                />            
                <div className='relative'>
                    <FVSection
                        japaneseTitle="お問い合わせ内容確認"
                        englishTitle="CONTACT"
                        overlayColor="bg-yellow-500"
                        src="/images/contact/fv_sp.jpg"
                        avifSrc="/images/contact/fv_sp.avif"
                        desktopSrc="/images/contact/fv.jpg"
                        desktopAvifSrc="/images/contact/fv.avif"
                        backgroundAlt="お問い合わせ"
                        breadcrumbs={[
                        { label: 'トップ', href: '/daichicloud/' },
                        { label: 'お問い合わせ内容確認' }
                        ]}
                        onNavigate={onNavigate}
                    />
                </div>
            </div>
            <div className='bg-gray-50 w-full'>
                <section className="px-4 pt-8 md:pt-20 pb-8 max-w-2xl mx-auto">
                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">お問い合わせ種別</label>
                            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{inquiryType}</p>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">会社・団体名</label>
                            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{companyName}</p>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">お名前</label>
                            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{name}</p>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">メールアドレス</label>
                            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{email}</p>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">電話番号</label>
                            <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{phoneNumber}</p>
                        </div>
                        {nearestStation && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">最寄り駅</label>
                                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{nearestStation}</p>
                            </div>
                        )}
                        {message && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">お問い合わせ内容</label>
                                <p className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100">{message}</p>
                            </div>
                        )}
                    </div>
                    <div className="flex space-x-4 mt-6">
                        <button
                            onClick={() => navigate('/daichicloud/contact', {
                                state: {
                                    inquiryType,
                                    companyName,
                                    name,
                                    email,
                                    phoneNumber,
                                    nearestStation,
                                    message
                                }
                            })}
                            className="w-full py-2 px-4 border border-1 border-gray-400 rounded-full shadow-sm text-sm font-medium text-black bg-gray-200 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            修正する
                        </button>
                        <button
                            onClick={handleSubmit}
                            disabled={isSubmitting} // 送信中はボタンを無効化
                            className="w-full py-2 px-4 border border-1 border-transparent rounded-full shadow-sm text-sm font-bold text-black bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                        >
                            {isSubmitting ? (
                                <CircularProgress size={24} /> // 送信中はローディングアイコンを表示
                            ) : (
                                '送信する'
                            )}
                        </button>
                    </div>
                </section>
            </div>
            </>
    );
};

export default ContactConfirm;

import React from 'react';
import { useLocation } from 'react-router-dom'; 
import ImageWithAnimation from './ImageWithAnimation';
import CustomLinkButton from './CustomLinkButton';

type ContactSectionProps = {
  message?: string;
  onNavigate?: (path: string) => void; // onNavigate関数を追加
};

const ContactSection: React.FC<ContactSectionProps> = ({
  message = "資料請求・お問い合わせは<br />以下よりお願い申し上げます",
  onNavigate,
}) => {
  const location = useLocation();

  // トップページかどうかを確認
  const isTopPage = location.pathname === '/';

  const handleNavigate = (path: string) => {
    if (onNavigate) {
      onNavigate(path);
    }
  };

  return (
    <section id="contact" className="relative bg-stone-900 flex flex-col">
      {!isTopPage && (
        <>
          <div className="relative md:h-[300px] md:pt-0">
            <div className="h-[400px] md:h-[300px] w-full relative top-0 left-0 bottom-0 right-0 m-auto overflow-y-hidden">
              <ImageWithAnimation 
                src="/images/common/footer_bg-3_sp.jpg"
                avifSrc="/images/common/footer_bg-3_sp.avif"    
                alt="CONTACT"
                width="100%"
                height={window.innerWidth >= 768 ? '280px' : '280px'}
                className="absolute inset-0 top-0" 
                overlayColor="bg-white"
                desktopSrc="/images/common/footer_bg-3.jpg"
                desktopAvifSrc="/images/common/footer_bg-3.avif"
                objectFit='cover'
              />
            </div>
            <div className="absolute z-40 left-0 -bottom-32 w-24 h-72 bg-yellow-500 opacity-50 hidden md:block"></div>
          </div>
        </>
      )}
      
      <div className='flex flex-col md:flex-row md:items-center md:justify-center md:space-x-8 md:pt-8 md:pb-14'>
        <div className="relative md:w-[480px] md:pt-0 bg-yellow-500">
          <div className="h-full w-[94%] md:w-[100%] relative top-[30px] md:top-[20px] md:left-[20px] right-0">
            <ImageWithAnimation 
              src="/images/common/footer_bg.jpg"
              avifSrc="/images/common/footer_bg.avif"    
              alt="CONTACT"
              width="100%"
              height="189px"
              className="object-cover"
              overlayColor="bg-white"
              desktopSrc="/images/common/footer_bg.jpg"
              desktopWidth="480px"
              desktopHeight="270px"
            />
          </div>
        </div>
        <div className="py-4">
          <div className="p-4">
            <p className="my-4 text-base text-white text-center" dangerouslySetInnerHTML={{ __html: message }}></p>
            <CustomLinkButton 
              href="/daichicloud/contact" 
              text="お問い合わせ" 
              buttonColor="bg-yellow-500 hover:bg-yellow-700" 
              textColor="text-black" 
              iconColor="text-black" 
              borderColor="border-none"
              width="w-[80%] md:w-[280px]"
              onNavigate={handleNavigate} // handleNavigateを渡す
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;

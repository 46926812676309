import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import ImageWithOverlay from '../components/ImageWithOverlay';
import ImageWithAnimation from '../components/ImageWithAnimation';
import CustomLinkButton from '../components/CustomLinkButton';
import FadeInUpSection from '../components/FadeInUpSection';
import SEO from '../components/SEO';
import ContactSection from '../components/ContactSection';
import ScrollFadeIn3D from '../components/ScrollFadeIn3DProps';
import useMediaQuery from '../hooks/useMediaQuery';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Link } from 'react-router-dom';

interface ImageProps {
  src: string;
  webpSrc: string;
  avifSrc: string;
  alt: string;
  className?: string;
  widthRatio?: number;
  heightRatio?: number;
  overlayColor?: string;
  animationDelay?: number;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
}

const kanrigamenImages: ImageProps[] = [
  {
    src: '/images/kanrigamen-1.png',
    webpSrc: '/images/kanrigamen-1.webp',
    avifSrc: '/images/kanrigamen-1.avif',
    alt: '管理画面1',
  },
  {
    src: '/images/kanrigamen-2.png',
    webpSrc: '/images/kanrigamen-2.webp',
    avifSrc: '/images/kanrigamen-2.avif',
    alt: '管理画面2',
  },
  {
    src: '/images/kanrigamen-3.png',
    webpSrc: '/images/kanrigamen-3.webp',
    avifSrc: '/images/kanrigamen-3.avif',
    alt: '管理画面3',
  },
];

const ResponsiveImageGrid: React.FC = () => {
  const [margins, setMargins] = useState([
    { marginLeft: '0px', marginTop: '0px' },
    { marginLeft: '80px', marginTop: '-80px' },
    { marginLeft: '160px', marginTop: '-50px' }
  ]);

  const updateMargins = useCallback(() => {
    if (window.innerWidth >= 768) {
      setMargins([
        { marginLeft: '-240px', marginTop: '0px' },
        { marginLeft: '300px', marginTop: '-220px' },
        { marginLeft: '0px', marginTop: '-30px' }
      ]);
    } else {
      setMargins([
        { marginLeft: '0px', marginTop: '0px' },
        { marginLeft: '90px', marginTop: '-110px' },
        { marginLeft: '180px', marginTop: '-100px' }
      ]);
    }
  }, []);

  useEffect(() => {
    updateMargins(); // 初期ロード時に実行
    window.addEventListener('resize', updateMargins);

    return () => window.removeEventListener('resize', updateMargins);
  }, [updateMargins]);

  return (
    <div className="relative w-full mb-20 md:pt-8 md:flex md:flex-col md:justify-center md:items-center">
      {kanrigamenImages.map((image, index) => (
        <FadeInUpSection key={index}>
          <div
            className="relative"
            style={{
              marginLeft: margins[index].marginLeft,
              marginTop: margins[index].marginTop,
              zIndex: index + 21, // Zインデックス
            }}
          >
            <picture>
              {image.avifSrc && <source srcSet={image.avifSrc} type="image/avif" />}
              {image.webpSrc && <source srcSet={image.webpSrc} type="image/webp" />}
              <img
                src={image.src}
                alt={image.alt}
                className="object-contain w-40 md:w-80 h-auto"
                width={405}
                height={321}
              />
            </picture>
          </div>
        </FadeInUpSection>
      ))}
    </div>
  );
};

const ResponsiveSteps: React.FC<{ steps: any[] }> = ({ steps }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const items = containerRef.current?.querySelectorAll('.flow-item') || [];
      items.forEach((item) => {
        const rect = item.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          item.classList.add('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={containerRef} className="relative mt-4 md:mt-20 md:max-w-3xl md:mx-auto">
      {steps.map((step, index) => {
        const IconComponent = step.icon;
        const fontSize = isMobile ? 60 : 80;
        return (
          <div key={index} className="relative flex mb-5 flow-item">
            {index !== steps.length - 1 && (
              <div className="flow-item-line"></div>
            )}
            <FadeInUpSection key={index} className='w-full flex space-x-1 mb-5'>
              <div>
                <img src={step.image} alt={`STEP.0${step.id}`} width={14} height={66} />
              </div>
              <div className="relative flex-1 flex items-center bg-white px-4 md:pt-8 md:pb-16 overflow-hidden border-2 border-transparent">
                <div className="relative flex items-center justify-center w-[24px] h-[24px] bg-gray-100 z-10 flex-shrink-0 border border-yellow-300">
                  <div className="w-[8px] h-[8px] bg-yellow-500"></div>
                </div>
                <div
                  className="text-lg md:text-2xl text-left pr-4 leading-[127%] z-12 ml-3"
                  dangerouslySetInnerHTML={{ __html: step.text }}
                ></div>
                <div className="absolute -right-4 -bottom-3">
                  <IconComponent className="text-gray-200" style={{ fontSize }} />
                </div>
              </div>
            </FadeInUpSection>
          </div>
        );
      })}
    </div>
  );
};

const ResponsiveImageWithAnimation = () => {
  const [imageSrc, setImageSrc] = useState('/images/special-price-mobile.png');
  const [overlayColor, setOverlayColor] = useState('bg-yellow-500');

  useEffect(() => {
    const updateImageProps = () => {
      if (window.innerWidth >= 768) {
        setImageSrc('/images/special-price-pc.png');
        setOverlayColor('bg-stone-900');
      } else {
        setImageSrc('/images/special-price-mobile.png');
        setOverlayColor('bg-yellow-500');
      }
    };

    // 初期表示のために呼び出し
    updateImageProps();

    // リサイズイベントを監視して画像と色を切り替える
    window.addEventListener('resize', updateImageProps);

    return () => {
      window.removeEventListener('resize', updateImageProps);
    };
  }, []);

  return (
    <ImageWithAnimation
      src={imageSrc}
      alt="月額利用料0円※売上分配方式"
      width="240px" // 画像コンテナの幅
      height="240px" // 画像コンテナの高さ
      imgWidth="100%" // imgタグの幅
      className="object-contain"
      overlayColor={overlayColor} // overlayColorを動的に設定
    />
  );
};

interface PlanningImagesProps {
  planningImages: string[]; // 文字列の配列として定義
}

const PlanningImages: React.FC<PlanningImagesProps> = ({ planningImages }) => {
  const isMobile = useMediaQuery('(max-width: 767px)'); // 画面幅が767px以下をモバイルと判断
  const containerHeight = isMobile ? 400 : 600; // モバイルでは400px、PCでは600pxを設定

  const centerY = containerHeight / 2; // コンテナの中央のY座標
  const yOffsetRange = 60; // 中央から-30pxから+30pxの範囲でY軸を調整

  // 上に寄せる割合を設定 (例えば全体の高さの20%を上に寄せる)
  const offsetPercentage = 0.2; // 20%上に寄せる
  const offsetAdjustment = containerHeight * offsetPercentage; // オフセットを割合で計算

  return (
    <div
      className="relative w-full my-10"
      style={{
        height: `${containerHeight}px`, // デバイスごとに異なる高さを設定
      }}
    >
      {planningImages.map((src, index) => {
        const itemCount = planningImages.length;
        const xPosition = (index / itemCount) * 100;
        const randomRotation = Math.random() * 8 - 4;
        const randomTranslateX = Math.random() * 20 - 10;

        // 要素の高さをランダムに決定
        const maxElementHeight = 100;
        const elementHeight = Math.random() * maxElementHeight + 50;

        // Y軸の位置を中央付近に収めるための計算
        const randomTranslateY = centerY - (elementHeight / 2) - 30 + Math.random() * yOffsetRange - offsetAdjustment;

        return (
          <div
            key={index}
            style={{
              transform: `rotate(${randomRotation}deg) translateX(${randomTranslateX}px)`,
              zIndex: index,
              position: "absolute",
              top: `${randomTranslateY}px`, // オフセットを考慮した中央基準の配置
              left: `calc(${xPosition}% - 24px)`,
              height: `${elementHeight}px`,
              width: 'auto',
              transformOrigin: "center",
            }}
          >
            <ScrollFadeIn3D width="100%" height="auto">
              <div className="w-full h-full">
                <img
                  src={src}
                  alt={`blueprint ${index + 1}`}
                  className="object-contain w-full h-full"
                />
              </div>
            </ScrollFadeIn3D>
          </div>
        );
      })}
    </div>
  );
};

const ResponsiveImageContainer: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  const mobileImageProps: ImageProps[] = [
    {
      src: '/images/mobile_service_header-1.jpg',
      webpSrc: '/images/mobile_service_header-1.webp',
      avifSrc: '/images/mobile_service_header-1.avif',
      alt: 'サービス1',
      className: 'flex-grow',
      widthRatio: 218,
      heightRatio: 312,
      overlayColor: 'bg-white',
      animationDelay: 1.0,
    },
    {
      src: '/images/mobile_service_header-2.jpg',
      webpSrc: '/images/mobile_service_header-2.webp',
      avifSrc: '/images/mobile_service_header-2.avif',
      alt: 'サービス2',
      className: 'flex-grow',
      widthRatio: 158,
      heightRatio: 252,
      overlayColor: 'bg-white',
      animationDelay: 0.5,
      marginTop: '-mt-12',
    },
  ];

  const desktopImageProps: ImageProps[] = [
    {
      src: '/images/service_header-1.jpg',
      webpSrc: '/images/service_header-1.webp',
      avifSrc: '/images/service_header-1.avif',
      alt: 'サービス1',
      className: 'flex-grow',
      widthRatio: 363,
      heightRatio: 434,
      animationDelay: 1.0,
      marginTop: '-mt-12',
      marginLeft: 'ml-auto',
    },
    {
      src: '/images/service_header-2.jpg',
      webpSrc: '/images/service_header-2.webp',
      avifSrc: '/images/service_header-2.avif',
      alt: 'サービス2',
      className: 'flex-grow',
      widthRatio: 883,
      heightRatio: 468,
      animationDelay: 0.5,
    },
  ];

  const imageProps = isMobile ? mobileImageProps : desktopImageProps;

  // PC版の画像表示のための計算
  if (!isMobile) {
    // 全体のwidthRatioの合計を計算し、undefinedを考慮してデフォルト値を使用
    const totalWidthRatio = imageProps.reduce((total, image) => total + (image.widthRatio || 1), 0);

    return (
      <div className="flex w-full gap-0">
        {imageProps.map((props, index) => {
          // widthRatioとheightRatioがundefinedの場合にデフォルト値を使用
          const widthRatio = props.widthRatio || 1;
          const heightRatio = props.heightRatio || 1;

          // 各画像の幅をウィンドウの幅に合わせて計算
          const boxWidthPercentage = (widthRatio / totalWidthRatio) * 100;
          const boxHeight = (heightRatio / widthRatio) * window.innerWidth * (widthRatio / totalWidthRatio);

          return (
            <div
              key={index}
              className={`${props.className} ${props.marginTop || ''} ${props.marginLeft || ''} ${props.marginRight || ''}`}
              style={{
                width: `${boxWidthPercentage}%`,
                height: `${boxHeight}px`,
              }}
            >
              <ImageWithAnimation
                src={props.src}
                alt={props.alt}
                imgWidth="100%"
                overlayColor={props.overlayColor || 'bg-transparent'}
              />
            </div>
          );
        })}
      </div>
    );
  }

  // モバイル版の表示
  return (
    <div className="flex w-full gap-0">
      {imageProps.map((props, index) => (
        <ImageWithOverlay
          key={index}
          src={props.src}
          alt={props.alt}
          className={`${props.className} ${props.marginTop || ''} ${props.marginLeft || ''} ${props.marginRight || ''}`}
          widthRatio={props.widthRatio}
          heightRatio={props.heightRatio}
          overlayColor={props.overlayColor}
          animationDelay={props.animationDelay}
        />
      ))}
    </div>
  );
};

type FloatingContactButtonProps = {
  onNavigate?: (path: string) => void; // onNavigate関数を追加
};

const FloatingContactButton: React.FC<FloatingContactButtonProps> = ({ onNavigate }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const contactSection = document.getElementById('contact');

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(!entry.isIntersecting);
      },
      {
        root: null, // viewportを基準
        threshold: 0, // セクションが少しでも表示されたら
      }
    );

    if (contactSection) {
      observer.observe(contactSection);
    }

    return () => {
      if (contactSection) {
        observer.unobserve(contactSection);
      }
    };
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    if (onNavigate) {
      e.preventDefault();
      onNavigate('/daichicloud/contact'); // onNavigate関数を使用してナビゲーションを制御
    }
  };

  return (
    <Link 
      to="/daichicloud/contact" 
      className={`fixed right-3 bottom-3 z-50 w-24 h-24 md:w-28 md:h-28 transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}
      style={{ padding: '10px' }}  // タップ領域を確保するためのパディング
      onClick={handleClick} // クリックハンドラーを追加
    >
      <div 
        className="relative flex items-center justify-center w-full h-full bg-transparent"
        style={{ padding: '5px' }} // 追加のパディングでタップ領域を広げる
      >
        <img
          src="/images/contact-btn.png"
          alt="お問い合わせ"
          className="w-20 h-20 md:w-26 md:h-26 object-contain" // 画像自体のサイズは維持
        />
      </div>
    </Link>
  );
};

const Home: React.FC<{ onNavigate: (path: string) => void }> = ({ onNavigate }) => {
  const text = "Payment Machineless Parking";
  const words = text.split(' ');
  const [startMarquee, setStartMarquee] = useState(false); // 流れるアニメーションを制御
  const [showSecondaryText, setShowSecondaryText] = useState(false);
  const [loading, setLoading] = useState(true); // ローディング状態を管理

  const data = [
    {
      id: 1,
      src: '/images/service-1.jpg',
      webpSrc: '/images/service-1.webp',
      avifSrc: '/images/service-1.avif',
      alt: 'コスト削減',
      title: 'COST SAVINGS',
      description: '精算機の設置が不要なため<br>集金も不要でコストが安い'
    },
    {
      id: 2,
      src: '/images/service-2.jpg',
      webpSrc: '/images/service-2.webp',
      avifSrc: '/images/service-2.avif',
      alt: '円滑な入出庫を実現',
      title: 'ACCESS AND <br>DEPARTURE',
      description: '精算機の待ち時間がなく<br>円滑な入出庫が可能'
    },
    {
      id: 3,
      src: '/images/service-3.jpg',
      webpSrc: '/images/service-3.webp',
      avifSrc: '/images/service-3.avif',
      alt: '管理の効率化',
      title: 'ENHANCED <br>CONTROL',
      description: '駐車場・駐輪場の状況を専用の<br>管理画面でリアルタイムに把握'
    },
    {
      id: 4,
      src: '/images/service-4.jpg',
      webpSrc: '/images/service-4.webp',
      avifSrc: '/images/service-4.avif',
      alt: '収益の最大化',
      title: 'PROFIT <br>GENERATION',
      description: '動的価格設定を実現し<br>収益の最大化が可能'
    }
  ];

  const steps = [
    { id: 1, image: '/images/step_1.svg', text: '駐車場に車を入庫', icon: LocalParkingIcon },
    { id: 2, image: '/images/step_2.svg', text: 'フラップが上がり車両をロック', icon: LockOutlinedIcon },
    { id: 3, image: '/images/step_3.svg', text: '出庫時にスマートフォンから利用料を精算', icon: SmartphoneIcon },
    { id: 4, image: '/images/step_4.svg', text: 'フラップが降下  出庫が完了', icon: LockOpenOutlinedIcon }
  ];

  const planningImages = [
    '/images/planning-image-1.jpg',
    '/images/planning-image-2.jpg',
    '/images/planning-image-3.jpg',
    '/images/planning-image-4.jpg',
    '/images/planning-image-5.jpg',
    '/images/planning-image-6.jpg',
    '/images/planning-image-7.jpg',
    '/images/planning-image-8.jpg',
  ];

  const refs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    refs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const maxScroll = document.getElementById('concept')?.offsetTop || window.innerHeight;
      const opacity = Math.min(scrollTop / maxScroll, 0.8);
  
      const overlayElement = document.getElementById('overlay');
      if (overlayElement) {
        overlayElement.style.background = `linear-gradient(rgba(0, 0, 0, ${opacity}), rgba(0, 0, 0, ${opacity}))`;
        const blurValue = scrollTop < maxScroll ? '0' : '4px';
        overlayElement.style.setProperty('backdrop-filter', `blur(${blurValue})`);
        overlayElement.style.setProperty('-webkit-backdrop-filter', `blur(${blurValue})`);
        overlayElement.style.setProperty('-moz-backdrop-filter', `blur(${blurValue})`);
        overlayElement.style.setProperty('-ms-backdrop-filter', `blur(${blurValue})`);
        overlayElement.style.setProperty('-o-backdrop-filter', `blur(${blurValue})`);
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleLoad = () => setLoading(false);
    const handleTimeout = () => setLoading(false);
  
    if (document.readyState === 'complete') {
      setLoading(false);
    } else {
      window.addEventListener('load', handleLoad);
      const timeoutId = setTimeout(handleTimeout, 10000); // 10秒後に強制的にローディング画面を解除
  
      return () => {
        window.removeEventListener('load', handleLoad);
        clearTimeout(timeoutId);
      };
    }
  }, []);
  
  useEffect(() => {
    console.log('Loading state:', loading);
  }, [loading]);

  useEffect(() => {
    // メインテキストのフェードインアップ後に流れるアニメーションを開始
    const marqueeStartTimer = setTimeout(() => {
      console.log('Starting marquee animation');
      setStartMarquee(true);
    }, (text.length * 0.02 + 1) * 100); // フェードインアップの合計時間を短縮    

    // 流れるアニメーション後の1秒後に日本語と英語のサブテキストのフェードインアップを開始
    const secondaryTextTimer = setTimeout(() => {
      console.log('Starting secondary text fade-in');
      setShowSecondaryText(true);
    }, 1000 + (text.length * 0.04 + 1) * 100 + 1000); // 流れるアニメーション後の1秒後

    return () => {
      clearTimeout(marqueeStartTimer);
      clearTimeout(secondaryTextTimer);
    };
  }, [text.length]);

  return (
    <div className="text-center bg-gray-100 overflow-x-hidden">
      <SEO 
        title="DAICHI CLOUD"
        description="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
        canonicalUrl="https://yourwebsite.com/home"
        ogTitle="Payment Machineless Parking - DAICHI CLOUD"
        ogDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
        ogImage="https://yourwebsite.com/images/og-image.jpg"
        ogUrl="https://yourwebsite.com/home"
        twitterTitle="DAICHI CLOUD"
        twitterDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
        twitterImage="https://yourwebsite.com/images/twitter-image.jpg"
        ldJson={{
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": "Payment Machineless Parking - DAICHI CLOUD",
          "description": "DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
        }}
      />    
      <Helmet>
        <link
          rel="preload"
          href="/images/top-fv.avif"
          as="image"
          type="image/avif"
          media="(max-width: 767px)"
        />
        <link
          rel="preload"
          href="/images/desktop-fv.avif"
          as="image"
          type="image/avif"
          media="(min-width: 768px)"
        />
        <link
          rel="preload"
          href="/images/top-fv.webp"
          as="image"
          type="image/webp"
          media="(max-width: 767px)"
        />
        <link
          rel="preload"
          href="/images/desktop-fv.webp"
          as="image"
          type="image/webp"
          media="(min-width: 768px)"
        />
      </Helmet>
      {loading && (
        <div className="fixed-full-screen loading-screen">
          <picture className="fixed inset-0 z-0 w-full h-full">
            <source 
              media="(min-width: 768px)" 
              type="image/avif" 
              srcSet="/images/desktop-fv.avif 1x, /images/desktop-fv@2x.avif 2x" 
            />
            <source 
              media="(min-width: 768px)" 
              type="image/webp" 
              srcSet="/images/desktop-fv.webp 1x, /images/desktop-fv@2x.webp 2x" 
            />        
            <source 
              type="image/avif" 
              srcSet="/images/top-fv.avif 1x, /images/top-fv@2x.avif 2x" 
            />
            <source 
              type="image/webp" 
              srcSet="/images/top-fv.webp 1x, /images/top-fv@2x.webp 2x" 
            />        
            <img 
              src="/images/top-fv.jpg" 
              alt="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。" 
              className="w-full h-full object-cover" 
            />
          </picture>
        </div>
      )}
      <div className={`${loading ? 'hidden' : ''}`}>
        <section className="flex flex-col items-center justify-center h-screen">
        <picture className="fixed inset-0 z-0 w-full h-full">
          <source 
            media="(min-width: 768px)" 
            type="image/avif" 
            srcSet="/images/desktop-fv.avif 1x, /images/desktop-fv@2x.avif 2x" 
          />
          <source 
            media="(min-width: 768px)" 
            type="image/webp" 
            srcSet="/images/desktop-fv.webp 1x, /images/desktop-fv@2x.webp 2x" 
          />        
          <source 
            type="image/avif" 
            srcSet="/images/top-fv.avif 1x, /images/top-fv@2x.avif 2x" 
          />
          <source 
            type="image/webp" 
            srcSet="/images/top-fv.webp 1x, /images/top-fv@2x.webp 2x" 
          />        
          <img 
            src="/images/top-fv.jpg" 
            alt="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。" 
            className="w-full h-full object-cover" 
          />
        </picture>
        <div id="overlay" className="fixed inset-0 z-5 w-full h-full"></div>
        <div className="w-full h-screen flex flex-col justify-center">
          <div className="relative flex items-center justify-start overflow-hidden">
            <div className="absolute inset-0 opacity-50 z-0"></div>
            <div className="mix-blend-overlay relative z-10 flex items-center justify-start">
              <Helmet>
                <link
                  rel="preload"
                  href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;700&text=PaymentMchinlesrkg&display=swap"
                  as="style"
                />
                <noscript>{`
                  <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;700&text=PaymentMchinlesrkg&display=swap"
                  />
                `}</noscript>
                <style>
                  {`
                    @font-face {
                      font-family: 'Noto Sans JP';
                      src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100&text=PaymentMchinlesrkg&display=swap');
                      font-weight: 100;
                      font-display: swap;
                    }
                    @font-face {
                      font-family: 'Noto Sans JP';
                      src: url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;700&text=PaymentMchinlesrkg&display=swap');
                      font-weight: 700;
                      font-display: swap;
                    }
                  `}
                </style>
              </Helmet>
              <h1 className={`whitespace-nowrap text-9xl md:text-10xl lg:text-11xl text-white font-thin leading-[140%] ${startMarquee ? 'animate-marquee-start' : ''}`}>
                {words.map((word, wordIndex) => (
                  <span key={wordIndex} className={`mr-8`}>
                    {word.split('').map((char, charIndex) => (
                      <span key={charIndex} style={{
                        animationDelay: `${(wordIndex * 5 + charIndex) * 0.02}s`, animationFillMode: 'forwards',
                        fontFamily: 'Noto Sans JP, sans-serif' // インラインでフォントを指定
                      }} className={`inline-block opacity-0 animate-fade-in-up`}>
                        {char}
                      </span>
                    ))}
                  </span>
                ))}
              </h1>
            </div>
          </div>
          <div className="relative z-10 text-white mt-4">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 text-shadow tracking-widest">
              {`精算機不要の\n次世代型駐車場・駐輪場`.split('\n').map((line, lineIndex) => (
                <div key={lineIndex} className="whitespace-nowrap">
                  {line.split('').map((char, charIndex) => (
                    <span
                      key={charIndex}
                      style={{ animationDelay: `${showSecondaryText ? ((text.length * 0.04) + (lineIndex * line.length * 0.04) + (charIndex * 0.04)) : '0s'}`, animationFillMode: 'forwards',
                    }} className="inline-block opacity-0 animate-fade-in-up">
                      {char}
                    </span>
                  ))}
                </div>
              ))}
            </h2>
            <p className="text-base md:text-lg text-shadow">
              {text.split(' ').map((word, wordIndex) => (
                <React.Fragment key={wordIndex}>
                  {word.split('').map((char, charIndex) => (
                    <span
                      key={charIndex}
                      style={{ animationDelay: `${showSecondaryText ? ((wordIndex * word.length * 0.04) + (charIndex * 0.04)) : '0s'}`, animationFillMode: 'forwards',
                      fontFamily: 'Noto Sans JP, sans-serif',
                      fontWeight: 'bold' // インラインでフォントを指定
                    }}
                      className="inline-block opacity-0 animate-fade-in-up"
                    >
                      {char}
                    </span>
                  ))}
                  <span>&nbsp;</span>
                </React.Fragment>
              ))}
            </p>
          </div>
        </div>
      </section>
      <div id="concept" className="relative z-10 text-white mt-4 flex align-center">
        <div className="px-4 py-12 mx-auto">
          <FadeInUpSection>
          <div className="text-2xl md:text-3xl text-yellow-500">CONCEPT</div>
          </FadeInUpSection>
          <FadeInUpSection>
          <div className="text-sm md:text-xl text-left md:text-center mt-4 md:mt-8 leading-[200%] md:leading-[210%] w-full w-[788px] mx-auto">
            機械式駐車場・駐輪場でフラップやラックを設置するには<br className="hidden md:block" />精算機の導入が当たり前でした。<br /><br className="hidden md:block" />
            しかし精算機はコストがかかり、<br className="hidden md:block" />現金の取り扱いにも盗難や不正のリスクが伴います。<br className="hidden md:block" />また精算機の混雑により<br className="hidden md:block" />スムーズに出庫できないという問題もあります。<br className="hidden md:block" />
            これらがパーキング開設の障壁となったり、<br className="hidden md:block" />開設をしてもなかなか投資回収ができないこともありました。<br /><br className="hidden md:block" />
            街中に駐車場・駐輪場が増え<br className="hidden md:block" />もっと豊かな車社会・自転車社会の実現のため、<br className="hidden md:block" />精算機が要らない駐車場・駐輪場を開発しました。
          </div>
          </FadeInUpSection>
        </div>
      </div>
      <div id="kanrigamen" className="relative w-full z-10 text-white mt-4 flex align-center justify-center">
        <div className="absolute left-0 top-0 w-20 h-32 bg-yellow-500 mix-blend-multiply opacity-50"></div>
        <div className="p-4 mt-36 w-full">
          <FadeInUpSection>
          <div className="text-yellow-500 text-4xl md:text-5xl md:leading-[130%]">シンプルかつ<br className="block md:hidden" />効率的な<br />パーキング管理</div>
          </FadeInUpSection>
          <FadeInUpSection>
          <div className="text-xl md:text-3xl md:leading-[170%] mt-4 md:mt-12 mb-6 leading-[200%] font-bold">
            DAICHI CLOUDは<br className="block md:hidden" />駐車場と駐輪場の管理を<br />シンプルかつ効率的に実現します<br /><br />専用の管理画面で<br className="block md:hidden" />PCやスマートフォンから<br />リアルタイムに売上や<br className="block md:hidden" />機器の状態・<br className="hidden md:block" />管理状況を<br className="block md:hidden" />いつでも確認できます<br />オーナー・管理会社で<br className="hidden md:block" />同時に<br className="block md:hidden" />利用でき 運営もスムーズ<br /><br />OEM提供も可能で<br className="block md:hidden" />自社ブランドとして<br />展開することができます
          </div>
          </FadeInUpSection>
          <ResponsiveImageGrid />
          <div className="flex flex-col md:flex-row items-center justify-center md:space-x-12">
            <div className="mt-4 flex flex-col items-center">
              <ResponsiveImageWithAnimation />
              <p className="text-xs mt-2">※売上分配方式</p>
            </div>
            <div className="mt-6 pb-8 w-full md:w-auto">
              <p className="md:mb-4">詳細はお問い合わせください</p>
              <CustomLinkButton 
                href="/daichicloud/contact" 
                text="お問い合わせ" 
                buttonColor="bg-yellow-500 hover:bg-yellow-700" 
                textColor="text-black" 
                iconColor="text-black" 
                borderColor="border-none"
                width="w-[80%] md:w-[280px]"
                onNavigate={onNavigate}
              />
            </div>
          </div>
        </div>
      </div>
      <div id="planning" className="relative w-full z-10 text-white mt-4 md:mt-12 flex align-center justify-center">
        <div className="px-4 pt-12 pb-28">
          <div className="text-2xl md:text-3xl text-yellow-500">2017年に特許を取得</div>
          <div className="text-sm md:text-xl leading-[200%] md:leading-[210%] mt-4 md:mt-8 mb-3 leading-[200%]">
            国の支援を得て 構想・開発に着手<br /><br />
            これまでとは違う <br className="block md:hidden" />まったく新しい駐車フラップ・<br />駐輪ラックを開発するため<br />
            私たちは今までの構造をゼロベースで見直し<br />
            数多くの設計図を作ってきました
          </div>
          <PlanningImages planningImages={planningImages} />
          <CustomLinkButton 
            href="/daichicloud/progress" 
            text="もっと見る" 
            buttonColor="bg-transparent hover:bg-white" 
            textColor="text-white hover:text-black" 
            iconColor="text-white"
            iconHoverColor="group-hover:text-black"
            borderColor="border-white border-2"
            width="w-[80%] md:w-[280px]"
            onNavigate={onNavigate}
            />
        </div>
      </div>
      <section className="bg-yellow-500 relative md:pb-8">
        <ResponsiveImageContainer />
        <div className="p-4 text-left pb-12 md:max-w-5xl md:mx-auto">
          <p className="text-xs md:text-base text-left mb-2">SERVICE</p>
          <h2 className="text-2xl md:text-4xl text-left">精算機を必要としない、<br />IoT型フラップ・駐輪ラック</h2>
          <div className="mt-4 md:mt-12 md:max-w-3xl md:mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
            {data.map((item, index) => (
              <div key={item.id} className="relative w-full">
                <ImageWithAnimation 
                  src={item.src} 
                  webpSrc={item.webpSrc} 
                  avifSrc={item.avifSrc}
                  alt={item.alt}
                  width="100%"
                  height="312px"
                  className="object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
                <div className="absolute bottom-0 left-0 w-full p-4">
                  <p className="text-yellow-500 text-xs text-shadow">{`0${index + 1}`}</p>
                  <p className="text-white text-3xl mb-2 opacity-50 text-shadow leading-[100%]" dangerouslySetInnerHTML={{ __html: item.title }}></p>
                  <p className="text-white text-xs mb-2 text-shadow">{item.alt}</p>
                  <p className="text-white text-base text-shadow" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className='bg-gray-100 relative'>        
        <ImageWithAnimation 
          src="/images/mobile-useflow.jpg"
          webpSrc="/images/mobile-useflow.webp"
          avifSrc="/images/mobile-useflow.avif"
          alt="利用方法"
          width="100%"
          height="284px"
          imgWidth="750px"
          className="object-cover"
          overlayColor="bg-stone-900"
          desktopSrc="/images/useflow.jpg"
          desktopWebpSrc="/images/useflow.webp"
          desktopAvifSrc="/images/useflow.avif"        
          desktopWidth="100%"
          desktopHeight="500px"
          desktopImgWidth="2732px"
          objectPosition="center bottom"
        />
        <div className="absolute right-0 top-20 w-[40%] md:w-[170px] h-80 md:h-[598px] bg-yellow-500 opacity-70"></div>
        <div className="px-4 pt-4 pb-12 mt-12 md:max-w-5xl md:mx-auto">
          <p className="text-xs md:text-base text-left mb-2">USE FLOW</p>
          <h2 className="text-2xl md:text-4xl text-left">利用方法</h2>
          <ResponsiveSteps steps={steps} />
        </div>
      </section>
      <ContactSection onNavigate={onNavigate} />
      <FloatingContactButton onNavigate={onNavigate} />
      </div>
    </div>
  );
};

export default Home;

import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useLocation } from 'react-router-dom'; // 現在のパスを取得するために使用
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import CustomLink from './CustomLink';

interface HeaderProps {
  onNavigate: (path: string) => void;
}

const Header = forwardRef(({ onNavigate }: HeaderProps, ref) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); // 現在のパスを取得

  useImperativeHandle(ref, () => ({
    closeMenu: () => setMenuOpen(false)
  }));

  const menuItems = [
    { name: 'トップ', path: '/daichicloud/' },
    { name: '理念', path: '/daichicloud/concept-vision' },
    { name: '開発の歩み', path: '/daichicloud/progress' },
    { name: 'お問い合わせ', path: '/daichicloud/contact' },

  ];

  const renderMenuItems = () => (
    menuItems.map((item, index) => {
      const isActive = location.pathname === item.path; // 現在のパスと一致するかどうかをチェック
      return isActive ? (
        <span
          key={index}
          className="text-black text-sm font-bold menu-item cursor-default active relative inline-block text-center"
        >
          {item.name}
          <span
            className="absolute left-1/2 transform -translate-x-1/2 bottom-[-2px] w-[50px] border-b-2 border-yellow-500"
            style={{ top: '100%' }} // ボーダーをテキストの下に配置
          />
        </span>
      ) : (
        <CustomLink
          key={index}
          to={item.path}
          className="text-black text-sm font-bold menu-item md:text-left text-center" // スタイルを変更
          onNavigate={path => {
            onNavigate(path); // メニューを閉じる処理はここでは行わない
          }}
        >
          {item.name}
        </CustomLink>
      );
    })
  );

  return (
    <header className="fixed top-0 left-0 w-full bg-gradient-to-b from-white to-gray-200 shadow-md z-20 border-t-4 border-yellow-500">
      <div className="container max-w-5xl mx-auto flex items-center justify-between px-5 py-3">
        <div className="flex items-center">
          <img src="/images/logo_b.svg" alt="DAICHI CLOUD" width={73} height={54} className="h-14 w-auto" />
        </div>
        <div className="md:hidden">
          <button onClick={() => setMenuOpen(!menuOpen)} aria-label={menuOpen ? "Close menu" : "Open menu"}>
            {menuOpen ? (
              <CloseIcon className="text-3xl text-gray-700" />
            ) : (
              <MenuIcon className="text-3xl text-gray-700" />
            )}
          </button>
        </div>
        <div className="hidden md:flex space-x-12">
          {renderMenuItems()}
        </div>
      </div>
      {menuOpen && (
        <div className="absolute top-18 left-0 w-full bg-white shadow-md z-10 md:hidden">
          <nav className="flex flex-col space-y-2 p-4">
            {renderMenuItems()}
          </nav>
        </div>
      )}
    </header>
  );
});

export default Header;

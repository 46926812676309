import React, { useEffect, useRef, ReactNode } from 'react';

type FadeInSectionProps = {
  children: ReactNode;
  className?: string; // クラス名を受け取れるようにする
};

const FadeInUpSection: React.FC<FadeInSectionProps> = ({ children, className = '' }) => {
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fade-in-up');
          entry.target.classList.add('active'); // `active` クラスを追加
          observer.unobserve(entry.target); // アニメーションが一度適用されたらオブザーバーを解除
        }
      });
    });

    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={domRef} className={`fade-in-section ${className}`}>
      {children}
    </div>
  );
};

export default FadeInUpSection;

import React from 'react';
import { Link } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type CustomLinkButtonProps = {
  href: string;
  text: string;
  customStyles?: string;
  isExternal?: boolean;
  buttonColor?: string;
  textColor?: string;
  iconColor?: string;
  iconHoverColor?: string;
  borderColor?: string;
  width?: string;
  onNavigate?: (path: string) => void; // onNavigate関数を追加
};

const CustomLinkButton: React.FC<CustomLinkButtonProps> = ({
  href,
  text,
  customStyles = '',
  isExternal = false,
  buttonColor = 'bg-yellow-500',
  textColor = 'text-black',
  iconColor = 'white', // 通常のアイコン色
  iconHoverColor = 'black', // ホバー時のアイコン色
  borderColor = 'border-none',
  width = 'w-[70%]',
  onNavigate // onNavigate関数を受け取る
}) => {
  const commonClasses = `relative shadow-lg ${width} rounded-full flex items-center mx-auto px-4 py-3 mt-2 ${buttonColor} ${textColor} ${borderColor} ${customStyles} group`;

  const handleClick = (e: React.MouseEvent) => {
    if (onNavigate) {
      e.preventDefault();
      onNavigate(href); // onNavigate関数を使用してナビゲーションを制御
    }
  };

  if (isExternal) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={commonClasses}
      >
        <span className="font-bold text-lg mx-auto">{text}</span>
        <KeyboardArrowRightIcon 
          sx={{ color: iconColor, transition: 'color 0.3s', '&:hover': { color: iconHoverColor } }} 
          className="absolute right-4" 
        />
      </a>
    );
  }

  return (
    <Link
      to={href}
      className={commonClasses}
      onClick={handleClick} // 内部リンクの場合はクリックハンドラーを追加
    >
      <span className="font-bold text-lg mx-auto">{text}</span>
      <KeyboardArrowRightIcon 
        sx={{ color: iconColor, transition: 'color 0.3s', '&:hover': { color: iconHoverColor } }} 
        className="absolute right-4" 
      />
    </Link>
  );
};

export default CustomLinkButton;

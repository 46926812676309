import React from 'react';

const ContactThankYou: React.FC<{ onNavigate: (path: string) => void }> = ({ onNavigate }) => {
  return (
    <div className="max-w-lg mx-auto mt-10 text-center px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">お問い合わせ<br className="block md:hidden" />ありがとうございます！</h1>
      <p className="text-md md:text-lg">お問い合わせいただき、<br className="block md:hidden" />誠にありがとうございます。<br /><br />
      お送りいただいた内容を確認の上、<br />担当者より折り返し<br className="block md:hidden" />ご連絡させていただきます。<br /><br />
      通常、2〜3営業日以内にご返信いたしますが、<br />内容によってはお時間をいただく場合が<br className="block md:hidden" />ございますので、<br className="hidden md:block" />何卒ご了承ください。</p>
    </div>
  );
};

export default ContactThankYou;

import React, { useEffect, useState, ReactNode } from 'react';
import ImageWithAnimation from './ImageWithAnimation';
import CustomLink from './CustomLink';
import { Helmet } from 'react-helmet';

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface FVSectionProps {
  japaneseTitle: string;
  englishTitle: string | ReactNode;
  src?: string;
  backgroundAlt?: string;
  desktopSrc?: string;
  avifSrc?: string;
  desktopAvifSrc?: string;
  overlayColor?: string;
  breadcrumbs?: BreadcrumbItem[];
  onNavigate?: (path: string) => void;
}

const FVSection: React.FC<FVSectionProps> = ({
  japaneseTitle,
  englishTitle,
  src,
  avifSrc,
  backgroundAlt,
  desktopSrc,
  desktopAvifSrc,
  overlayColor = 'bg-black',
  breadcrumbs = [],
  onNavigate
}) => {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="https://fonts.cdnfonts.com/s/58106/Phenomena-Bold.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
        <link href="https://fonts.cdnfonts.com/css/phenomena" rel="stylesheet" />
        <style>
          {`
            @font-face {
              font-family: 'Phenomena';
              src: url('https://fonts.cdnfonts.com/s/58106/Phenomena-Bold.woff') format('woff');
              font-weight: bold;
              font-display: block; /* フォントがロードされるまで他のフォントを表示しない */
            }
          `}
        </style>
      </Helmet>
      <div className={`relative w-full h-full ${src && backgroundAlt ? 'pb-[65px]' : ''}`}>
        {breadcrumbs.length > 0 && !isDesktop && (
          <nav className="px-4 pt-10">
            <ol className="flex space-x-1 text-xs text-gray-700">
              {breadcrumbs.map((breadcrumb, index) => (
                <li key={index} className="flex items-center">
                  {breadcrumb.href ? (
                    <CustomLink
                      to={breadcrumb.href}
                      onNavigate={onNavigate}
                      className="hover:underline"
                    >
                      {breadcrumb.label}
                    </CustomLink>
                  ) : (
                    <span>{breadcrumb.label}</span>
                  )}
                  {index < breadcrumbs.length - 1 && (
                    <span className="mx-2">
                      <div className="w-6 h-[1px] bg-black"></div>
                    </span>
                  )}
                </li>
              ))}
            </ol>
          </nav>
        )}
        <div className={`relative ${src && backgroundAlt ? 'mt-10 md:mt-[150px]' : 'mt-10'}`}>
          {src && backgroundAlt && (
            <div className="relative top-16 md:top-20 w-full h-[135px] md:h-[280px] left-0 z-10 overflow-hidden bg-gray-50 ">
              <ImageWithAnimation
                src={src}
                avifSrc={avifSrc}
                desktopSrc={desktopSrc}
                desktopAvifSrc={desktopAvifSrc}
                alt={backgroundAlt}
                className="absolute inset-0 top-0"
                overlayColor={overlayColor}
                width="100%"
                imgWidth="100%"
                objectPosition="center"
                objectFit="cover"
                height={window.innerWidth >= 768 ? '280px' : '135px'}
              />
            </div>
          )}
          <div className="absolute z-30 right-0 -bottom-52 w-24 h-72 bg-yellow-500 mix-blend-multiply opacity-70 hidden md:block"></div>
          <div className="absolute z-30 right-0 top-0 w-12 h-32 bg-yellow-500 mix-blend-multiply opacity-70 block md:hidden"></div>
          <div className={`flex flex-col md:flex-row-reverse justify-between z-20 top-0 left-0 right-0 w-full md:max-w-5xl md:mx-auto ${src && backgroundAlt ? 'absolute' : 'relative md:pt-10'}`}>
            {breadcrumbs.length > 0 && isDesktop && (
              <nav className="px-4 relative z-20">
                <ol className="flex space-x-1 text-xs text-gray-600">
                  {breadcrumbs.map((breadcrumb, index) => (
                    <li key={index} className="flex items-center">
                      {breadcrumb.href ? (
                        <CustomLink
                          to={breadcrumb.href}
                          onNavigate={onNavigate}
                          className="hover:underline"
                        >
                          {breadcrumb.label}
                        </CustomLink>
                      ) : (
                        <span>{breadcrumb.label}</span>
                      )}
                      {index < breadcrumbs.length - 1 && (
                        <span className="mx-2">
                          <div className="w-6 h-[1px] bg-black"></div>
                        </span>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            )}
            <div className="flex flex-col justify-center px-4 space-y-1">
              <h1 className="text-base md:text-xl font-medium text-gray-600">{japaneseTitle}</h1>
              <p className="text-6xl md:text-8xl font-bold" style={{ fontFamily: 'Phenomena, sans-serif' }}>
                {typeof englishTitle === 'string' ? englishTitle : englishTitle}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FVSection;

import React from 'react';
import { Link } from 'react-router-dom';

interface CustomLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  onNavigate?: (path: string) => void; // onNavigateをオプショナルにする
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, children, className, onNavigate }) => {
  const handleClick = (e: React.MouseEvent) => {
    if (onNavigate) {
      e.preventDefault();
      onNavigate(to); // onNavigate関数を使用してナビゲーションを制御
    }
  };

  return (
    <Link
      to={to}
      className={className}
      onClick={handleClick} // クリックハンドラを追加
    >
      {children}
    </Link>
  );
};

export default CustomLink;

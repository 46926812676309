import React, { ReactNode } from 'react';

interface IntroductionSectionProps {
  borderColor?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  titleColor?: string; // タイトルのテキストカラー
  subtitleColor?: string; // サブタイトルのテキストカラー
}

const IntroductionSection: React.FC<IntroductionSectionProps> = ({
  borderColor = 'bg-yellow-500',
  title = 'INTRODUCTION',
  subtitle,
  titleColor = 'text-black', // デフォルトで黒
  subtitleColor = 'text-gray-700', // デフォルトでグレー
}) => {
  return (
    <div className="relative z-10 md:max-w-4xl mx-auto md:mb-10">
      <div className={`w-5 h-1 md:h-2 ${borderColor} mb-4`}></div>
      <p className={`text-4xl md:text-6xl mb-4 font-medium ${titleColor}`}>{title}</p>
      <h2 className={`text-2xl md:text-3xl ${subtitleColor}`}>{subtitle}</h2>
    </div>
  );
};

export default IntroductionSection;

import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';

interface ImageWithAnimationProps {
  src: string;
  alt: string;
  width?: string; // 画像の元の幅を表す
  height?: string; // 画像の元の高さを表す
  imgWidth?: string; // 実際の画像表示領域の幅
  className?: string;
  overlayColor?: string;
  desktopSrc?: string;
  desktopWidth?: string;
  desktopHeight?: string;
  desktopImgWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
  objectPosition?: string;
  objectFit?: 'contain' | 'cover'; // 新たに追加
  webpSrc?: string;
  avifSrc?: string;
  desktopWebpSrc?: string;
  desktopAvifSrc?: string;
}

const ImageWithAnimation: React.FC<ImageWithAnimationProps> = ({
  src,
  alt,
  width = '100%',
  height = 'auto',
  imgWidth = '100%',
  className = '',
  overlayColor = 'bg-yellow-500',
  desktopSrc,
  desktopWidth,
  desktopHeight,
  desktopImgWidth,
  maxWidth,
  maxHeight,
  objectPosition = 'center',
  objectFit = 'contain', // デフォルトは contain
  webpSrc,
  avifSrc,
  desktopWebpSrc,
  desktopAvifSrc,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);

  const [currentSrc, setCurrentSrc] = useState(src);
  const [currentWebpSrc, setCurrentWebpSrc] = useState(webpSrc);
  const [currentAvifSrc, setCurrentAvifSrc] = useState(avifSrc);
  const [currentWidth, setCurrentWidth] = useState(width);
  const [currentHeight, setCurrentHeight] = useState(height);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && desktopSrc) {
        setCurrentSrc(desktopSrc || src);
        setCurrentWebpSrc(desktopWebpSrc || webpSrc);
        setCurrentAvifSrc(desktopAvifSrc || avifSrc);
        setCurrentWidth(desktopWidth || width);
        setCurrentHeight(desktopHeight || height);
      } else {
        setCurrentSrc(src);
        setCurrentWebpSrc(webpSrc);
        setCurrentAvifSrc(avifSrc);
        setCurrentWidth(width);
        setCurrentHeight(height);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [src, desktopSrc, webpSrc, avifSrc, desktopWebpSrc, desktopAvifSrc, width, height, desktopWidth, desktopHeight]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // 一度だけ発火させる
        }
      },
      {
        threshold: 0.1, // 10% 表示されたときに発動
      }
    );

    const currentRef = imgRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={imgRef}
      className={`relative overflow-hidden ${className}`} // クラス名に従ってボックスサイズを決定
    >
      <motion.div
        className={`absolute inset-0 ${overlayColor}`}
        initial={{ clipPath: 'inset(0 100% 0 0)' }}
        animate={{ clipPath: isVisible ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)' }}
        transition={{ duration: 0.5, ease: [0.62, -0.01, 0.38, 1.01] }}
      ></motion.div>
      <picture>
        {currentAvifSrc && <source srcSet={currentAvifSrc} type="image/avif" />}
        {currentWebpSrc && <source srcSet={currentWebpSrc} type="image/webp" />}
        <motion.img
          src={currentSrc}
          alt={alt}
          width={currentWidth} // 画像の元の幅
          height={currentHeight} // 画像の元の高さ
          className={`object-${objectFit}`} // object-fit クラスを動的に適用
          style={{
            objectPosition: objectPosition,
          }}
          initial={{ clipPath: 'inset(0 100% 0 0)' }}
          animate={{ clipPath: isVisible ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)' }}
          transition={{ delay: 0.5, duration: 0.5, ease: [0.62, -0.01, 0.38, 1.01] }}
        />
      </picture>
    </div>
  );
};

export default ImageWithAnimation;

import React, { useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import Home from './pages/Home';
import Progress from './pages/Progress';
import ConceptVision from './pages/ConceptVision';
import ScrollToTop from './components/ScrollToTop';
import Contact from './pages/Contact';
import ContactConfirm from './pages/ContactConfirm';
import ContactThankYou from './pages/ContactThankYou';

const App: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const [targetPath, setTargetPath] = useState<string | null>(null);
  const headerRef = useRef<{ closeMenu: () => void }>(null);

  const slideVariants = {
    initial: { x: '100%' },
    animate: { x: '0%' },
    exit: {}, // スライド後、停止する位置
  };

  const handleNavigate = (path: string) => {
    if (location.pathname !== path) {
      setTargetPath(path); // 目標パスを保存
      setIsAnimating(true); // アニメーションを開始
    }
  };

  const handleAnimationComplete = () => {
    if (targetPath) {
      navigate(targetPath); // アニメーション完了後にページ遷移
      setIsAnimating(false); // アニメーション状態をリセット
      setTargetPath(null); // ターゲットパスをリセット
      if (headerRef.current) {
        headerRef.current.closeMenu(); // ページ遷移後にメニューを閉じる
      }
    }
  };

  // Define paths where the copyright text should not appear
  const hideCopyrightPaths = ['/daichicloud/contact', '/daichicloud/contact/confirm', '/daichicloud/contact/thank-you'];
  const shouldHideCopyright = hideCopyrightPaths.includes(location.pathname);

  return (
    <div>
      <Header onNavigate={handleNavigate} ref={headerRef} />
      <main className={`relative ${location.pathname !== '/daichicloud/' ? 'mt-[87px]' : ''}`}>
        <div className="relative z-10">
          <Routes location={location} key={location.pathname}>
            <Route path="/daichicloud/" element={<Home onNavigate={handleNavigate} />} />
            <Route path="/daichicloud/progress" element={<Progress onNavigate={handleNavigate} />} />
            <Route path="/daichicloud/concept-vision" element={<ConceptVision onNavigate={handleNavigate} />} />
            <Route path="/daichicloud/contact" element={<Contact onNavigate={handleNavigate} />} />
            <Route path="/daichicloud/contact/confirm" element={<ContactConfirm onNavigate={handleNavigate} />} />
            <Route path="/daichicloud/contact/thank-you" element={<ContactThankYou onNavigate={handleNavigate} />} />
          </Routes>
        </div>
        <AnimatePresence mode="wait">
          {isAnimating && (
            <motion.div
              className="fixed inset-0 bg-yellow-500 z-20"
              initial="initial"
              animate="animate"
              exit="exit"
              variants={slideVariants}
              transition={{ duration: 0.75, ease: 'easeInOut' }}
              onAnimationComplete={handleAnimationComplete}
            />
          )}
        </AnimatePresence>
      </main>
        <p className={`bg-white relative text-center text-xs py-2 z-10 ${shouldHideCopyright ? 'hidden' : 'block'}`}>© DAICHI Co, Ltd. All Rights Reserved.</p>
    </div>
  );
};

const AppWrapper: React.FC = () => (
  <Router>
    <ScrollToTop />
    <App />
  </Router>
);

export default AppWrapper;

import React from 'react';
import FVSection from '../components/FVSection';
import SEO from '../components/SEO';
import IntroductionSection from '../components/common';
import ImageWithAnimation from '../components/ImageWithAnimation';
import ContactSection from '../components/ContactSection';
import ImageWithOverlay from '../components/ImageWithOverlay';


const ConceptVision: React.FC<{ onNavigate: (path: string) => void }> = ({ onNavigate }) => {
  return (
    <div className=''>    
        <SEO 
            title="理念 | DAICHI CLOUD"
            description="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            canonicalUrl="https://yourwebsite.com/home"
            ogTitle="理念 | DAICHI CLOUD"
            ogDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            ogImage="https://yourwebsite.com/images/og-image.jpg"
            ogUrl="https://yourwebsite.com/home"
            twitterTitle="理念 | DAICHI CLOUD"
            twitterDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            twitterImage="https://yourwebsite.com/images/twitter-image.jpg"
            ldJson={{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "理念 | DAICHI CLOUD",
                "description": "DAICHI CLOUDの開発進捗を追跡できます。",
                "url": "https://yourwebsite.com/progress"
            }}
        />
        <FVSection
          japaneseTitle="理念"
          englishTitle={<>CONCEPT & <br />VISION</>}
          src="/images/concept-vision/fv_sp.jpg"
          avifSrc="/images/concept-vision/fv_sp.avif"
          desktopSrc="/images/concept-vision/fv.jpg"
          desktopAvifSrc="/images/concept-vision/fv.avif"
          backgroundAlt="理念"
          overlayColor="bg-yellow-500"
          breadcrumbs={[
            { label: 'トップ', href: '/daichicloud/' },
            { label: '理念' }
          ]}
          onNavigate={onNavigate}
        />
        <section className="px-4 py-14 md:py-20 w-full bg-gray-50 relative">
          <div className="border-r-[18px] md:border-r-[100px] border-r-yellow-50 h-full absolute right-0 md:right-auto md:left-0 top-0"></div>
          <IntroductionSection title="ISSUE" subtitle={<>パーキング経営から見えた<br className="block md:hidden" />精算機の課題</>} />
          <p className="py-6 text-base leading-[200%] text-gray-900 md:max-w-3xl md:mx-auto relative z-10">
          私たちは、25年以上にわたり、西多摩エリアを中心に100箇所以上の駐車場・駐輪場を運営・展開してきました。その運営において欠かせなかったのが精算機です。精算機は、無人での運営を可能にし、人的コストの削減に大きく貢献するとともに、ユーザーが自身のペースで支払いを行えるというメリットを提供してきました。<br /><br />
          しかし、精算機の導入および維持には大きなコストがかかり、現金の取り扱いや盗難、不正リスクなど、多くの課題が伴いました。また、精算機の混雑による出庫の遅れも、スムーズな利用を妨げる要因となっていました。<br /><br />
          近年では、フラップも必要としない「フラップレス」駐車場も登場しています。しかし、フラップレス駐車場では物理的なロックが機能しないため、不正行為が発生した場合、カメラで追跡する必要があり、その後の請求に人的コストがかかってしまいます。そのため、私たちはこれらの課題を解決する新しいシステムの開発に取り組むことにしました。
          </p>
        </section>
        <section className="relative bg-gray-800 flex flex-col pb-16">
          <div className="relative bg-yellow-500">
            <div className="w-[94%] relative top-[30px] md:top-[40px] right-0 overflow-y-hidden">            
            <ImageWithOverlay
              src="/images/concept-vision/solution_sp.jpg"
              desktopSrc='/images/concept-vision/solution.jpg'
              alt="SOLUTION"
              widthRatio={480}
              heightRatio={270}
              overlayColor="bg-white"
              className="md:max-h-[500px] object-potision-center object-cover"
            />
            </div>
          </div>
          <div className="pt-24 md:pt-32 px-4 pb-4">
            <IntroductionSection title="SOLUTIONS" titleColor='text-white' subtitle={<>次世代の駐車場・<br className="block md:hidden" />駐輪場管理へ</>} subtitleColor='text-white' />
            <p className="py-6 text-base leading-[200%] text-white md:max-w-3xl md:mx-auto relative z-10">
            私たちは、25年以上にわたり、西多摩エリアを中心に100箇所以上の駐車場・駐輪場を運営・展開してきました。その運営において欠かせなかったのが精算機です。精算機は、無人での運営を可能にし、人的コストの削減に大きく貢献するとともに、ユーザーが自身のペースで支払いを行えるというメリットを提供してきました。<br /><br />
            しかし、精算機の導入および維持には大きなコストがかかり、現金の取り扱いや盗難、不正リスクなど、多くの課題が伴いました。また、精算機の混雑による出庫の遅れも、スムーズな利用を妨げる要因となっていました。<br /><br />
            近年では、フラップも必要としない「フラップレス」駐車場も登場しています。しかし、フラップレス駐車場では物理的なロックが機能しないため、不正行為が発生した場合、カメラで追跡する必要があり、その後の請求に人的コストがかかってしまいます。そのため、私たちはこれらの課題を解決する新しいシステムの開発に取り組むことにしました。
            </p>
          </div>
        </section>
        <section className='relative'>
          <div className="relative w-full h-[270px] md:h-[400px] left-0 z-10 overflow-hidden">
            <ImageWithAnimation
              src="/images/concept-vision/future_sp.jpg"
              avifSrc="/images/concept-vision/future_sp.avif"
              desktopSrc="/images/concept-vision/future.jpg"
              desktopAvifSrc="/images/concept-vision/future.avif"
              alt="FUTURE"
              className="absolute inset-0 top-0" // 高さ指定を削除
              width="100%"
              imgWidth="100%"
              objectPosition="center"
              objectFit='cover'
              height={window.innerWidth >= 768 ? '400px' : '270px'} // 動的に高さを指定
            />
          </div>
          <div className="absolute z-30 right-0 md:left-0 top-48 md:top-18 md:bottom-auto md:top-52 w-12 md:w-24 h-28 md:h-72 bg-yellow-500 opacity-50"></div>
          <div className="py-16 px-4 relative">
            <div className="border-r-[18px] md:border-r-[100px] border-r-yellow-50 h-full absolute right-0 md:right-auto md:left-0 top-0"></div>
            <IntroductionSection title="FUTURE" subtitle={<>より豊かな車社会・<br className="block md:hidden" />自転車社会をめざして</>} />
            <p className="py-6 text-base leading-[200%] text-gray-900 md:max-w-3xl md:mx-auto relative z-10">
            DAICHI CLOUDを活用し、より多くの企業や駐車場オーナーをサポート、<br />幅広いシーンでの駐車場・駐輪場の管理を実現していきます。<br /><br />
            DAICHI CLOUDは、駐車場会社に限らず、多様なユーザーが導入できるように設計されており、OEM提供も可能なシステムとなっています。これにより、自社ブランドとしての展開も可能です。<br /><br />
            またこの取り組みを更に一歩進め、ゲートシステムの開発にも着手しています。駐車フラップ・駐輪ラックは、各駐車・駐輪スペースに設置するため、スペースの数だけ機器が必要です。しかしゲートシステムであれば、出入口に設置するだけで精算機レスの駐車場・駐輪場が誕生。<br />
            設置コストの削減や運営の効率化が図られるとともに、ユーザーにとっても一層便利なパーキング環境を提供します。<br />
            私たちは、街中に駐車場・駐輪場が増え、より豊かな車社会・自転車社会が実現する未来を目指し、今後も新たな技術開発とサービス向上に努めてまいります。DAICHI CLOUDを通じて、より良いパーキング運営をサポートし続けます。
            </p>
          </div>
        </section>
        <ContactSection onNavigate={onNavigate} />
      </div>
  );
};

export default ConceptVision;
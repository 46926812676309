import React, { useEffect, useState, useRef, ReactNode } from 'react';
import { motion } from 'framer-motion';

interface ScrollFadeIn3DProps {
  children: ReactNode;
  width?: string;
  height?: string;
  className?: string;
  animationDelay?: number;
}

const ScrollFadeIn3D: React.FC<ScrollFadeIn3DProps> = ({
  children,
  width = '100%',
  height = 'auto',
  className = '',
  animationDelay = 0,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);

  // ランダムな回転角度を生成
  const initialRotateX = Math.random() * 360 - 180; // -180度から180度の範囲
  const initialRotateY = Math.random() * 360 - 180; // -180度から180度の範囲
  const initialRotateZ = Math.random() * 360 - 180; // -180度から180度の範囲
  const finalRotateX = Math.random() * 10 - 5; // -5度から5度の範囲で最終的な微調整
  const finalRotateY = Math.random() * 10 - 5; // -5度から5度の範囲で最終的な微調整
  const finalRotateZ = Math.random() * 60 - 30; // -30度から30度の範囲で最終的な微調整

  // ランダムなスピードを生成
  const randomDuration = 0.5 + Math.random() * 0.2; // 0.5秒から0.7秒の範囲でランダムなスピード

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.1,
      }
    );

    const currentRef = imgRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <motion.div
      ref={imgRef}
      className={`relative overflow-hidden ${className}`}
      style={{
        width,
        height,
      }}
      initial={{
        opacity: 0,
        y: -200,
        rotateX: initialRotateX,
        rotateY: initialRotateY,
        rotateZ: initialRotateZ, // 初期状態: ランダムなZ軸の回転
      }}
      animate={{
        opacity: isVisible ? 1 : 0,
        y: isVisible ? 0 : -200,
        rotateX: isVisible ? finalRotateX : initialRotateX, // 最終的に微調整した回転角度に
        rotateY: isVisible ? finalRotateY : initialRotateY,
        rotateZ: isVisible ? finalRotateZ : initialRotateZ, // 最終的にばらばらなZ軸の回転角度に
      }}
      transition={{
        delay: animationDelay,
        duration: randomDuration, // ランダムなスピード設定
        ease: 'easeOut', // 緩やかに停止する動きを設定
      }}
    >
      {children}
    </motion.div>
  );
};

export default ScrollFadeIn3D;

import React, { useEffect, useRef } from 'react';
import FVSection from '../components/FVSection';
import SEO from '../components/SEO';
import IntroductionSection from '../components/common';
import ImageWithAnimation from '../components/ImageWithAnimation';
import ContactSection from '../components/ContactSection';
import FadeInUpSection from '../components/FadeInUpSection';
import ImageWithOverlay from '../components/ImageWithOverlay';

const steps = [
  {
    id: 1,
    image: '/images/progress/2017.svg',
    text: '特許取得',
  },
  { 
    id: 2, 
    text: 'メカ構想',
    image1: '/images/progress/planning-image-11-1.jpg',
    caption1: 'ボルトタイプ構想図',
    image1Width: 600,
    image1Height: 400,
    image2: '/images/progress/planning-image-11-2.jpg',
    caption2: '駐輪機構構想図',
    image2Width: 600,
    image2Height: 400,
  },
  {
    id: 3,
    text: '側構部イメージ',
    image1: '/images/planning-image-1.jpg',
    image1Width: 392,
    image1Height: 392,
  },
  {
    id: 4,
    text: '駐輪ラック図面',
    image1: '/images/planning-image-2.jpg',
    image1Width: 800,
    image1Height: 800,
  },
  {
    id: 5,
    text: '駐輪ラック図面',
    image1: '/images/planning-image-3.jpg',
    image1Width: 800,
    image1Height: 800,
    image2: '/images/progress/planning-image-3-2.jpg',
    image2Width: 800,
    image2Height: 800,
  },
  {
    id: 6,
    text: '駐輪ラック図面',
    image1: '/images/planning-image-4.jpg',
    image1Width: 800,
    image1Height: 800,
    image2: '/images/progress/planning-image-4-2.jpg',
    image2Width: 800,
    image2Height: 800,
  },
  {
    id: 7,
    text: '駐輪ラック図面',
    image1: '/images/planning-image-5.jpg',
    image1Width: 800,
    image1Height: 800,
    image2: '/images/progress/planning-image-5-2.jpg',
    image2Width: 800,
    image2Height: 800,
  },
  {
    id: 8,
    text: '駐輪ロック機構図面',
    image1: '/images/progress/planning-image-9.jpg',
    image1Width: 800,
    image1Height: 566,
    image2: '/images/progress/planning-image-9-2.jpg',
    image2Width: 800,
    image2Height: 800,
  },
  {
    id: 9,
    text: '駐輪ラック図面',
    image1: '/images/planning-image-6.jpg',
    image1Width: 800,
    image1Height: 800,
  },
  {
    id: 10,
    text: '駐輪ラック図面',
    image1: '/images/planning-image-7.jpg',
    image1Width: 800,
    image1Height: 800,
  },
  {
    id: 11,
    text: '駐車フラップカバー図面',
    image1: '/images/progress/planning-image-10.jpg',
    image1Width: 800,
    image1Height: 800,
  },
  {
    id: 12,
    text: '駐輪ロック相関図',
    image1: '/images/planning-image-8.jpg',
    image1Width: 800,
    image1Height: 800,
    image2: '/images/progress/planning-image-8-2.jpg',
    image2Width: 800,
    image2Height: 8566,
  },
];

const ResponsiveSteps: React.FC<{ steps: any[] }> = ({ steps }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      const items = containerRef.current?.querySelectorAll('.flow-item') || [];
      items.forEach((item) => {
        const rect = item.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          item.classList.add('active');
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div ref={containerRef} className="relative mt-4 md:mt-8 md:max-w-3xl md:mx-auto">
      {steps.map((step, index) => (
        <div key={index} className="relative flex mb-5 flow-item">
          {index !== steps.length - 1 && (
            <div className="flow-item-line"></div>
          )}
          <FadeInUpSection className="w-full flex space-x-1">
            <div style={{ width: 14 }}>
              {step.image && (
                <img src={step.image} alt={`STEP.0${step.id}`} width={14} height={66} />
              )}
            </div>
            <div
              className={`relative flex-1 flex items-center px-4 py-6 overflow-hidden ${
                index === 0 ? 'bg-yellow-50 border-2 border-yellow-500' : 'bg-white border-2 border-transparent'
              }`}
            >
              <div className="relative flex items-start w-full">
                <div className="relative flex items-center justify-center w-[24px] h-[24px] z-10 flex-shrink-0 border border-yellow-300 bg-gray-100">
                  <div className="w-[8px] h-[8px] bg-yellow-500"></div>
                </div>
                <div className="flex-1">
                  <div
                    className={`text-lg md:text-2xl text-left pr-4 leading-[127%] z-12 ml-3 ${
                      index === 0 ? 'font-bold' : ''
                    }`}
                    dangerouslySetInnerHTML={{ __html: step.text }}
                  ></div>
                  {(step.image1 || step.image2) && (
                    <div className="mt-4 flex space-x-4">
                      {step.image1 && (
                        <div className="flex flex-col items-center space-y-2">
                          <ImageWithAnimation 
                            src={step.image1} 
                            alt={step.caption1 ? step.caption1 : step.text} 
                            width={step.image1Width} 
                            height={step.image1Height} 
                            className="w-[130px] h-auto object-contain" 
                          />
                          {step.caption1 && <p className="text-xs mt-2">{step.caption1}</p>}
                        </div>
                      )}
                      {step.image2 && (
                        <div className="flex flex-col items-center space-y-2">
                          <ImageWithAnimation 
                            src={step.image2} 
                            alt={step.caption2 ? step.caption2 : step.text} 
                            width={step.image2Width} 
                            height={step.image2Height} 
                            className="w-[130px] h-auto object-contain" 
                          />
                          {step.caption2 && <p className="text-xs mt-2">{step.caption2}</p>}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </FadeInUpSection>
        </div>
      ))}
    </div>
  );
};

const Progress: React.FC<{ onNavigate: (path: string) => void }> = ({ onNavigate }) => {
  return (
    <div>    
        <SEO 
            title="開発の歩み | DAICHI CLOUD"
            description="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            canonicalUrl="https://yourwebsite.com/home"
            ogTitle="開発の歩み | DAICHI CLOUD"
            ogDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            ogImage="https://yourwebsite.com/images/og-image.jpg"
            ogUrl="https://yourwebsite.com/home"
            twitterTitle="開発の歩み | DAICHI CLOUD"
            twitterDescription="DAICHI CLOUDは駐車場と駐輪場の管理をシンプルかつ効率的に実現します。"
            twitterImage="https://yourwebsite.com/images/twitter-image.jpg"
            ldJson={{
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "開発の歩み | DAICHI CLOUD",
                "description": "DAICHI CLOUDの開発進捗を追跡できます。",
                "url": "https://yourwebsite.com/progress"
            }}
        />
        <FVSection
          japaneseTitle="開発の歩み"
          englishTitle="PROGRESS"
          src="/images/progress/fv_sp.jpg"
          avifSrc="/images/progress/fv_sp.avif"
          desktopSrc="/images/progress/fv.jpg"
          desktopAvifSrc="/images/progress/fv.avif"
          backgroundAlt="開発の歩み"
          overlayColor="bg-yellow-500"
          breadcrumbs={[
            { label: 'トップ', href: '/daichicloud/' },
            { label: '開発の歩み' }
          ]}
          onNavigate={onNavigate}
        />
        <section className="px-4 py-14 md:py-20 w-full bg-gray-50 relative">
          <div className="border-r-[18px] md:border-r-[100px] border-r-yellow-50 h-full absolute right-0 md:right-auto md:left-0 top-0"></div>
          <IntroductionSection title="INTRODUCTION" subtitle={<>駐車場管理の<br />新しい形をめざして</>} />
          <p className="py-6 text-base leading-relaxed text-gray-900 md:max-w-3xl md:mx-auto relative z-10">
            DAICHI CLOUDの開発は、コインパーキングにおける駐車場管理の新しい形を追求することから始まりました。私たちの目標は、上下に動くフラップ（車止め）を遠隔で制御し、車両や二輪車の出入りを効率的かつ安全に管理するシステムを構築することです。<br /><br />
            このプロジェクトの初期段階では、IoT技術を活用して、駐車場の各機器を遠隔操作する仕組みを検証しました。ハッキングや妨害からシステムを守るため、通信環境に閉域通信網を活用する計画を立てました。これにより、インターネットを通じて安全かつ安定したシステム運用を実現します。<br /><br />
            このページでは、開発当初のコンセプトから現在の進捗まで、プロジェクトの進化と設計の変遷を振り返りながら紹介していきます。
          </p>
        </section>
        <section className="relative bg-gray-300 flex flex-col pb-16">
          <div className="relative bg-yellow-500">
            <div className="w-[94%] relative top-[30px] md:top-[40px] right-0 overflow-y-hidden">            
            <ImageWithOverlay
              src="/images/progress/gallery_sp.jpg"
              avifSrc="/images/progress/gallery_sp.avif"
              desktopSrc="/images/progress/gallery.jpg" 
              desktopAvifSrc="/images/progress/gallery.avif"     
              alt="GALLERY"
              widthRatio={480}
              heightRatio={270}
              overlayColor="bg-white"
              className="md:max-h-[500px] object-potision-center object-cover"
            />
            </div>
          </div>
          <div className="pt-24 md:pt-32 px-4 pb-4">            
            <IntroductionSection title="GALLERY" subtitle="設計の軌跡を辿る" />
          </div>
          <div className="px-4">
            <ResponsiveSteps steps={steps} />
          </div>
        </section>
        <ContactSection onNavigate={onNavigate} />
    </div>
  );
};

export default Progress;

import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

interface ImageWithOverlayProps {
  src: string;
  avifSrc?: string;
  desktopSrc?: string;
  desktopAvifSrc?: string;
  alt: string;
  widthRatio?: number;
  heightRatio?: number;
  className?: string;
  overlayColor?: string; // 四角の色を指定するパラメータ
  animationDelay?: number; // アニメーションの遅延時間
}

const ImageWithOverlay: React.FC<ImageWithOverlayProps> = ({
  src,
  avifSrc,
  desktopSrc,
  desktopAvifSrc,
  alt,
  widthRatio,
  heightRatio,
  className = '',
  overlayColor = 'bg-yellow-500',
  animationDelay = 0,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    const handleScroll = () => {
      const element = document.getElementById(alt);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [alt]);

  const aspectRatioEnabled = widthRatio && heightRatio;
  const paddingBottom = aspectRatioEnabled ? `${(heightRatio / widthRatio) * 100}%` : 'auto';

  const selectedAvifSrc = isDesktop ? desktopAvifSrc : avifSrc;
  const selectedSrc = isDesktop ? desktopSrc : src;

  return (
    <div
      className={`relative overflow-hidden ${className}`}
      style={{
        flexBasis: aspectRatioEnabled ? `${widthRatio}px` : 'auto',
        flexGrow: aspectRatioEnabled ? widthRatio : 'auto',
      }}
    >
      <div style={{ position: 'relative', width: '100%', paddingBottom: aspectRatioEnabled ? paddingBottom : 'unset' }}>
        <motion.div
          className={`absolute inset-0 ${overlayColor}`}
          initial={{ clipPath: 'inset(0 100% 0 0)' }}
          animate={{ clipPath: isVisible ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)' }}
          transition={{ duration: 0.5, ease: [0.62, -0.01, 0.38, 1.01], delay: animationDelay }}
        ></motion.div>
        <picture>
          {selectedAvifSrc && (
            <source
              srcSet={selectedAvifSrc}
              type="image/avif"
            />
          )}
          <motion.img
            id={alt}
            src={selectedSrc}
            alt={alt}
            className="absolute inset-0 w-full h-full object-cover"
            initial={{ clipPath: 'inset(0 100% 0 0)' }}
            animate={{ clipPath: isVisible ? 'inset(0 0 0 0)' : 'inset(0 100% 0 0)' }}
            transition={{ delay: 1.5 + animationDelay, duration: 0.5, ease: [0.62, -0.01, 0.38, 1.01] }}
          />
        </picture>
      </div>
    </div>
  );
};

export default ImageWithOverlay;
